import { createInitializerQueueList } from './initializer.manager';
import { StagDetectorService } from '../services/stag-detector.service';
import { EnvironmentProviders, inject, Injector, provideAppInitializer } from '@angular/core';
import { DecoratorService } from '../helpers/decorator-service.service';
import { GlobalEventsService } from '../services/global-events.service';
import { GoogleTagManagerService } from '../services/google-tag-manager.service';
import { PwaService } from '../services/pwa.service';
import { UserPaymentModeService } from '../services/user/user-payment-mode.service';
import { SeoService } from '../services/seo.service';
import { WebSocketService } from '../services/web-socket.service';
import { ThemeService } from '../services/theme.service';
import { ZendeskChatService } from '../services/zendesk/zendesk-chat.service';
import { TelegramAppService } from '../services/telegram-app.service';
import { CheckWebpService } from '../services/check-webp.service';

/**
 * The `initApp` function initializes the DecoratorService with the provided Injector.
 * @param {Injector} injector - The `injector` parameter in the `initApp` function is of type
 * `Injector`. It is used to provide dependencies to the application components. The `Injector` is a
 * dependency injection container that resolves dependencies for classes and services in Angular
 * applications.
 * @returns A function is being returned.
 */
export function initApp(injector: Injector) {
  DecoratorService.injector = injector;
}

export const provideInitializer = () => {
  return [
    provideAppInitializer(() => {
      const injector = inject(Injector);
      initApp(injector);
      return Promise.resolve();
    }),
    ...parallelAppInitializer,
  ];
};

const parallelAppInitializer: EnvironmentProviders[] = createInitializerQueueList([
  {
    token: GlobalEventsService,
    initializerFn: (service: GlobalEventsService) => service.initGlobalEvent(),
  },
  {
    token: GoogleTagManagerService,
    initializerFn: (service: GoogleTagManagerService) => service.addGtmToDom(),
  },
  {
    token: PwaService,
    initializerFn: (service: PwaService) => service.registerServiceWorker(),
  },
  {
    token: UserPaymentModeService,
    initializerFn: (service: UserPaymentModeService) => service.resolveModeFromUrl(),
  },
  {
    token: SeoService,
    initializerFn: (service: SeoService) => service.updateGlobalHeadLink(),
  },
  {
    token: StagDetectorService,
    initializerFn: (service: StagDetectorService) => service.detectParams(),
  },
  {
    token: WebSocketService,
    initializerFn: (service: WebSocketService) => service.createConnection(),
  },
  {
    token: ThemeService,
    initializerFn: (service: ThemeService) => service.resolveTheme$(),
  },
  {
    token: ZendeskChatService,
    initializerFn: (service: ZendeskChatService) => service.initChat(),
  },
  {
    token: TelegramAppService,
    initializerFn: (service: TelegramAppService) => service.detectTelegramApp(),
  },
  {
    token: CheckWebpService,
    initializerFn: (service: CheckWebpService) => service.checkIfWebpSupported(),
  },
]);
